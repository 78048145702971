<template>
  <div class="reviewMain" ref="sendRecord" @scroll="scroll">
    <div>
      <div>
        <el-form :inline="true" class="demo-form-inline">
         <el-form-item label="单位:">
              <el-select v-model="search.institutionId" size="medium" clearable @change="handleInstitution"
                  placeholder="请选择单位" class="form-line-item">
                  <el-option v-for="(item,index) in institutionList" :key='index'
                      :label="item.institutionName" :value="item.institutionId"></el-option>
              </el-select>
          </el-form-item>
          <el-form-item label="批次:">
            <el-select v-model="search.batchId" size='medium' clearable placeholder="请选择批次" :disabled='batchShow'
                       class="form-line-item">
              <el-option v-for="(item,index) in batchList" :key='item.batchId' :label="item.batchName"
                         :value="item.batchId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="论文题目:">
            <el-input placeholder="请输入论文题目" style="width:350px;" v-model="search.title" size="medium" class="form-line-item"></el-input>
          </el-form-item>

          <el-form-item label="论文编号:">
            <el-input placeholder="请输入论文编号" style="width:180px;" v-model="search.oldId" size="medium" class="form-line-item"></el-input>
          </el-form-item>

          <el-form-item label="学院:">
            <el-input placeholder="请输入学院" style="width:180px;" v-model="search.studentCollege" size="medium" class="form-line-item"></el-input>
          </el-form-item>

          <el-form-item label="学位类型:">
            <el-select v-model="search.sendingRuleId" size="medium" clearable placeholder="请选择规则" class="form-line-item">
              <el-option
                  v-for="(item,index) in sendRuleList"
                  :key="item.sendingRuleId"
                  :label="item.degreeName"
                  :value="item.sendingRuleId"
              ></el-option>
            </el-select>
          </el-form-item>




        </el-form>
        <el-form :inline="true" class="demo-form-inline">
          <!-- <el-form-item label="编号:">
              <el-input placeholder="请输入论文编号" v-model="search.oldId" size='medium' class="form-line-item">
              </el-input>
          </el-form-item>-->

          <!-- <el-form-item label="状态:">
              <el-select v-model="search.state" size="medium" clearable placeholder="请选择状态"
                  class="form-line-item">
                  <el-option label="待送审" :value="2"></el-option>
                  <el-option label="评审中" :value="0"></el-option>
                  <el-option label="已完成" :value="1"></el-option>
              </el-select>
          </el-form-item>-->
          <el-form-item label="学生:">
            <el-input placeholder="请输入学生姓名" v-model="search.author" size="medium" class="form-line-item"></el-input>
          </el-form-item>

          <el-form-item label="学号:">
            <el-input placeholder="请输入学生学号" v-model="search.studentNum" size="medium" class="form-line-item"></el-input>
          </el-form-item>


          <el-form-item label="导师:">
            <el-input placeholder="请输入导师姓名" style="width:170px;" v-model="search.supervisor" size="medium" class="form-line-item"></el-input>
          </el-form-item>

          <el-form-item label="评审状态:">
            <el-select v-model="search.state" size="medium" clearable placeholder="请选择评审状态" class="form-line-item" style="width: 170px;">
              <el-option label="评审中" value="2"></el-option>
              <el-option label="部分返回" value="0"></el-option>
              <el-option label="全部返回" value="1"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="评审结果:">
            <el-select v-model="search.isPass" size="medium" clearable placeholder="请选择是否通过" class="form-line-item" style="width: 170px;">
              <el-option label="未通过" value="0"></el-option>
              <el-option label="全通过" value="1"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="导出范围:">
            <el-select v-model="search.downloadState" size="medium" clearable placeholder="请选择导出范围" class="form-line-item" style="width: 170px;">
              <el-option value="0" label="已全导出"></el-option>
              <el-option value="1" label="部分导出"></el-option>
              <el-option value="2" label="还未导出"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="分差大于:" style="margin-right: 20px; display: inline-block;">
            <el-input v-model="search.scoreDifference" placeholder="请输入分差大于的值" size="medium" class="form-line-item" style="width: 170px;"></el-input>
          </el-form-item>

<!--          <el-form-item style="margin-left:10px;">-->
<!--            <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">搜索</el-button>-->
<!--            <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">重置</el-button>-->
<!--          </el-form-item>-->
        </el-form>


        <el-form :inline="true" class="demo-form-inline">
          <el-form-item style="margin-left:10px;">
            <el-button type="primary" size="mini" icon="el-icon-search" @click="batchSearch">搜索</el-button>
            <el-button type="success" size="mini" icon="el-icon-refresh" @click="resetHandle">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 表格 -->
    <div>
      <vxe-toolbar
        perfect
        style="background-color:#fff;padding:0px 10px;"
        :refresh="{query: refresh}"
      >
        <template #buttons>
          <el-button
            type="success"
            size="mini"
            @click="exportReviewHandle"
            icon="el-icon-download"
          >批量导出评阅书</el-button>
          <!-- <el-button type="success" size="mini">批量打印评阅书</el-button> -->
          <el-button
            type="success"
            size="mini"
            @click="exportTableHandle"
            icon="el-icon-download"
          >导出评审信息表</el-button>
        </template>
      </vxe-toolbar>
      <!-- :loading="loading" -->
      <vxe-table
        :header-cell-style="headerCellStyle"
        class="mytable-scrollbar paperPage"
        round
        align="left"
        ref="paperTable"
        highlight-current-row
        highlight-hover-row
        :keyboard-config="{isArrow: true}"
        show-footer
        :footer-method="footerMethod"
        :checkbox-config="{trigger: 'cell', highlight: true, range: false}"
        :loading="loading"
        :data="paperList"
      >
        <vxe-column type="checkbox" width="50" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column type="seq" title="序号" width="50" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="title" title="题目" min-width="280" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="oldId" title="论文编号" min-width="100" :show-overflow="'tooltip'"></vxe-column>
         <vxe-column field="institutionName" title="单位" width="180" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="batchName" title="批次" width="150" :show-overflow="'tooltip'"></vxe-column>
        <!-- <vxe-column field="endTime" title="截至时间" width="120" :show-overflow="'tooltip'"></vxe-column> -->
        <vxe-column field="studentCollege" title="学院" width="120" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="degreeName" title="学位类型" width="150" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="studentName" title="学生" width="90" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="studentNum" title="学号" width="100" :show-overflow="'tooltip'"></vxe-column>

        <vxe-column field="supervisor" title="导师" width="90" :show-overflow="'tooltip'"></vxe-column>

        <!-- <vxe-column field="sendingNum" title="待送审" width="120" sortable>
            <template #default="{ row }">
                {{row.sendingNum}}/{{row.expertNumber}}
            </template>
        </vxe-column>-->

        <vxe-column field="reviewNum" title="评审中" width="90" :sortable="true" :sort-method="(a, b) => (a.reviewNum+a.sendingNum) - (b.reviewNum+b.sendingNum)">
          <template #default="{ row }">
            {{row.reviewNum + row.sendingNum}}/{{row.expertNumber}}
          </template>
        </vxe-column>
        <vxe-column field="finishedNum" title="已完成" width="90" sortable>
          <template #default="{ row }">
            {{row.finishedNum}}/{{row.expertNumber}}
          </template>
        </vxe-column>
        <vxe-column field="reviewExportTime" title="已导出" width="90" sortable>
          <template #default="{ row }">
            {{row.reviewExportTime}}/{{row.expertNumber}}
          </template>
        </vxe-column>
        <vxe-column field="notPassNum" title="未通过" width="90" sortable>
          <template #default="{ row }">
            <div
              :style="{color: row.notPassNum == 0?'':'red'}"
            >{{row.notPassNum}}/{{row.expertNumber}}</div>
          </template>
        </vxe-column>
        <!--                <vxe-column field="notPassNum" title="未通过" width="150" sortable>-->
        <!--                  <template #default="{ row }">-->
        <!--                    {{row.notPassNum}}/{{row.expertNumber}}-->
        <!--                  </template>-->
        <!--                </vxe-column>-->
        <vxe-column title="详情" width="50" fixed="right">
          <template #default="{ row }">
            <el-button
              size="mini"
              type="text"
              @click="singleConditionHandle(row.paperId,row.batchId,row.title,row.oldId)"
            >详情</el-button>
            <!-- <el-button
              :type="row.finishedNum === row.expertNumber ? 'primary' : 'info'"
              :disabled="row.finishedNum !== row.expertNumber"
              size="mini"
              type="text"
              @click="addDialog(row.paperId,row.batchId,row.title)"
            >加送</el-button> -->
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager
        perfect
        align="right"
        :current-page.sync="page.pageIndex"
        :page-size.sync="page.pageSize"
        :total="page.total"
        :page-sizes="page.pageSizes"
        :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
        @page-change="handlePageChange"
      >
        <template #left>
          <vxe-button size="small" @click="firstPage">首页</vxe-button>
        </template>
      </vxe-pager>
    </div>
    <!-- 加送弹窗 -->
    <vxe-modal
      v-model="addModel"
      :position="{top:'0px'}"
      width="500"
      :show-footer="true"
      show-zoom
      resize
      :transfer="true"
    >
      <template #title>
        <span>加送</span>
      </template>
      <vxe-form :data="addData" title-align="right" title-width="100">
        <vxe-form-item field="expertName" title="专家数量:" :span="22" :item-render="{}">
          <template #default="{ data }">
            <vxe-input v-model="data.expertName" placeholder="请输入" size="medium"></vxe-input>
          </template>
        </vxe-form-item>
        <!-- <vxe-form-item
          field="debExpertInstitution"
          title="是否回避初审专家单位："
          :span="24"
          :item-render="{}"
        >
          <template #default="{ data }">
            <vxe-radio-group v-model="data.debExpertInstitution">
              <vxe-radio :label="1" content="是" style="width:80px;"></vxe-radio>
              <vxe-radio :label="0" content="否" style="width:80px;"></vxe-radio>
            </vxe-radio-group>
          </template>
        </vxe-form-item>-->
        <vxe-form-item field="returnTime" title="截至时间:" :span="22" :item-render="{}">
          <template #default="{ data }">
            <el-date-picker
              v-model="data.returnTime"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="请选择日期"
              size="medium"
            ></el-date-picker>
          </template>
        </vxe-form-item>
      </vxe-form>
      <template #footer>
        <el-button type="primary" size="small" @click="addPaper">确定</el-button>
      </template>
    </vxe-modal>

    <!-- 弹窗 -->
    <el-dialog
      title="送审情况"
      :visible.sync="receiptDiaVis"
      :close-on-click-modal="false"
      width="50%"
      class="dialog"
    >
      <div>论文关键词：{{paperKeywords}}</div>
      <div>
        <el-table :data="receiptList">
          <el-table-column property="expertName" label="姓名" width="150"></el-table-column>
          <el-table-column property="university" label="单位" width="200"></el-table-column>
          <el-table-column property="resultKeywords" label="匹配结果关键词"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">{{$store.state.mapper.stateMap[scope.row.state]}}</template>
          </el-table-column>
          <el-table-column label="评阅书">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleLook(scope.row.reviewUrl)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="receiptDiaVis = false">取 消</el-button>
        <el-button type="primary" @click="receiptDiaVis = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="sendProcessTitle"
      :show-close="showClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="sendProcessVis"
      center
      width="30%"
    >
      <el-progress :text-inside="true" :stroke-width="24" :percentage="process" status="success"></el-progress>
      <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>-->
    </el-dialog>
    <!-- 弹窗 -->
    <vxe-modal v-model="exportReviewModel" :position="{top: '0px'}" width="650" height="430" :show-footer='true'
               show-zoom resize :transfer='true'>
      <template #title>
        导出评阅书
      </template>
      <vxe-form :data="download" title-align="right" title-width="250">
        <!-- <vxe-form-item field="sendType" title="已选评阅书数：" :span="24" :item-render="{}">
            <template #default="{ data }">
                <span style="color:red;">{{download.reviewNum}}</span> 份
            </template>
        </vxe-form-item> -->
        <vxe-form-item>
          <span style="font-weight: bold;margin-left: 30px">评阅书导出格式与内容:</span>
        </vxe-form-item>
        <vxe-form-item title="评阅书导出文件格式：" :span="24" :item-render="{}">
          <template #default="{ data }">
            <vxe-radio-group v-model="data.form">
              <vxe-radio :label="0" content="WORD" style="width:80px;"></vxe-radio>
              <vxe-radio :label="1" content="PDF" style="width:80px;"></vxe-radio>
              <vxe-radio :label="2" content="全部" style="width:80px;"></vxe-radio>
            </vxe-radio-group>
          </template>
        </vxe-form-item>
        <vxe-form-item field="sendType" title="是否包含评阅人信息：" :span="24" :item-render="{}">
          <template #default="{ data }">
            <vxe-radio-group v-model="data.isWithExpert">
              <vxe-radio :label="1" content="是" style="width:80px;"></vxe-radio>
              <vxe-radio :label="0" content="否" style="width:80px;"></vxe-radio>
              <vxe-radio :label="2" content="全部" style="width:80px;"></vxe-radio>
            </vxe-radio-group>
          </template>
        </vxe-form-item>
        <vxe-form-item :span="24" :item-render="{}">
          <span style="font-weight: bold;margin-left: 30px">评阅书导出文件夹分类方式:</span>
        </vxe-form-item>

        <vxe-form-item field="sendType" title="论文字段分类：" :span="24" :item-render="{}">
          <template #default="{ data }">
            <vxe-radio-group v-model="data.isWithMajor">
              <vxe-radio :label="1" content="学院" style="width:80px;"></vxe-radio>
              <vxe-radio :label="2" content="论文编号" style="width:110px;"></vxe-radio>
              <vxe-radio :label="0" content="无" style="width:80px;"></vxe-radio>
            </vxe-radio-group>
          </template>
        </vxe-form-item>

        <vxe-form-item title="首层文件夹显示：" :span="24" :item-render="{}" style="width: 100%;">
          <template #default="{ data }">
            <vxe-radio-group v-model="data.type">
              <vxe-radio :label="1" content="是否包含评阅人信息" ></vxe-radio>
              <vxe-radio :label="2" content="论文字段分类" ></vxe-radio>
            </vxe-radio-group>
          </template>
        </vxe-form-item>
        <!-- <vxe-form-item title="评阅书导出文件格式：" :span="24" :item-render="{}">
            <template #default="{ data }">
                <vxe-checkbox-group v-model="data.format">
                    <vxe-checkbox label="WORD" content="WORD" style="width:70px;"></vxe-checkbox>
                    <vxe-checkbox label="PDF" content="PDF" style="width:70px;"></vxe-checkbox>
                </vxe-checkbox-group>
            </template>
        </vxe-form-item>
        <vxe-form-item field="paperType" title="评阅书导出范围：" :span="24" :item-render="{}">
            <template #default="{ data }">
                <vxe-radio-group v-model="data.range">
                    <vxe-radio :label="1" content="是" style="width:70px;"></vxe-radio>
                    <vxe-radio :label="0" content="否" style="width:70px;"></vxe-radio>
                </vxe-radio-group>
            </template>
        </vxe-form-item>-->
      </vxe-form>
<!--        <el-button type="primary" size="small" @click="confirmExportReview">确认</el-button>-->
      <template #footer>
        <el-button type="primary" size="small" @click='confirmExportReview'>确认</el-button>
      </template>
    </vxe-modal>
<!--    &lt;!&ndash; 导出评阅表弹窗 &ndash;&gt;-->
<!--    <vxe-modal v-model="exportTableModel" :position="{top: '0px'}" width="800" :show-footer='true' show-zoom resize-->
<!--               :transfer='true'>-->
<!--      <template #title>-->
<!--        导出评审信息表-->
<!--      </template>-->
<!--      <vxe-form :data="download" title-align="right" title-width="200">-->
<!--        <vxe-form-item title="选择导出评审表格式：" :span="12" :item-render="{}">-->
<!--          <template #default="{ data }">-->
<!--            <vxe-select v-model="data.format" placeholder="请选择" @change="changeHandle" size="medium"-->
<!--                        transfer clearable>-->
<!--              <vxe-option value="表1" label="表1"></vxe-option>-->
<!--              <vxe-option value="表2" label="表2"></vxe-option>-->
<!--              <vxe-option value="表3" label="表3"></vxe-option>-->
<!--              <vxe-option value="自定义" label="自定义"></vxe-option>-->
<!--            </vxe-select>-->
<!--          </template>-->
<!--        </vxe-form-item>-->
<!--      </vxe-form>-->
<!--      <el-form class="editName">-->
<!--        <el-form-item label="论文信息：" label-width="20%">-->
<!--          <el-checkbox-group v-model="formData.paperInfos">-->
<!--            <el-checkbox v-for="item in paperInfos" :label="item"></el-checkbox>-->
<!--          </el-checkbox-group>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="专家信息：" label-width="20%">-->
<!--          <el-checkbox-group v-model="formData.expertInfos">-->
<!--            <el-checkbox v-for="item in expertInfos" :label="item"></el-checkbox>-->
<!--          </el-checkbox-group>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="评阅书信息：" label-width="20%">-->
<!--          <el-checkbox-group v-model="formData.reviewInfos">-->
<!--            <el-checkbox v-for="item in reviewInfos" :label="item"></el-checkbox>-->
<!--          </el-checkbox-group>-->
<!--        </el-form-item>-->
<!--      </el-form>-->
<!--      <template #footer>-->
<!--        <el-button type="primary" size="small" @click='cfmExportSave'>导出并预存</el-button>-->
<!--        <el-button type="primary" size="small" @click='cfmExport'>确认</el-button>-->
<!--      </template>-->
<!--    </vxe-modal>-->
    <vxe-modal v-model="exportTableSaveModel" :position="{top: '0px'}" width="500" :show-footer='true' show-zoom
               resize :transfer='true'>
      <template #title>
        保存为新评审信息表
      </template>
      <vxe-form :data="download" title-align="right" title-width="150">
        <vxe-form-item title="预存名称：" :span="20" :item-render="{}">
          <template #default="{ data }">
            <vxe-input v-model="data.format" placeholder="请输入文本" type="text"></vxe-input>
          </template>
        </vxe-form-item>
      </vxe-form>
      <template #footer>
        <el-button type="primary" size="small" @click="cfmExport">确认</el-button>
      </template>
    </vxe-modal>

    <vxe-modal
      v-model="singleConditionModel"
      :position="{top: '0px'}"
      width="990"
      :show-footer="false"
      show-zoom
      resize
      :transfer="true"
    >
      <template #title>详情</template>
<!--      <el-row type="flex" justify="end" style="padding-bottom:5px">-->
<!--        <el-col :span="2">-->
<!--          <el-button type="primary" size="small" @click="complaints">申诉</el-button>-->
<!--        </el-col>-->
<!--        <el-col :span="2">-->
<!--          <el-popover ref="popover2" placement="right" trigger="click">-->
<!--            <el-form-->
<!--              label-position="left"-->
<!--              ref="reviewForm"-->
<!--              :model="reviewFormList"-->
<!--              :rules="rules"-->
<!--              :inline="true"-->
<!--              class="demo-form-inline"-->
<!--              style="display: flex; flex-direction: column;"-->
<!--            >-->
<!--              <el-form-item label="原专家" label-width="110px" prop="experts">-->
<!--                <el-checkbox-group v-model="reviewFormList.experts" size="small">-->
<!--                  <el-checkbox-->
<!--                    v-for="expert in this.reviewList"-->
<!--                    :key="expert.expertId"-->
<!--                    v-if="expert.expertName"-->
<!--                    :label="expert.expertId"-->
<!--                  >{{ expert.expertName }}</el-checkbox>-->
<!--                </el-checkbox-group>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="类型" label-width="110px" prop="type">-->
<!--                <el-radio-group v-model="reviewFormList.type" size="small">-->
<!--                  <el-radio :label="1">指定</el-radio>-->
<!--                  <el-radio :label="0">回避</el-radio>-->
<!--                </el-radio-group>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="新增专家" label-width="110px" prop="expertNum">-->
<!--                <el-input-->
<!--                  placeholder="请输入新增专家数量"-->
<!--                  style="width:230px;"-->
<!--                  v-model="reviewFormList.expertNum"-->
<!--                  size="medium"-->
<!--                  class="form-line-item"-->
<!--                ></el-input>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="是否告知专家为复审论文" label-width="110px" prop="note">-->
<!--                <el-radio-group v-model="reviewFormList.note" size="small">-->
<!--                  <el-radio :label="1">是</el-radio>-->
<!--                  <el-radio :label="0">否</el-radio>-->
<!--                </el-radio-group>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="截至时间" label-width="100px" prop="returnTime">-->
<!--                <el-date-picker-->
<!--                  v-model="reviewFormList.returnTime"-->
<!--                  type="date"-->
<!--                  format="yyyy-MM-dd"-->
<!--                  value-format="yyyy-MM-dd"-->
<!--                  placeholder="请选择日期"-->
<!--                  size="medium"-->
<!--                ></el-date-picker>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="是否替换论文" label-width="110px" prop="replacePaper">-->
<!--                <el-radio-group v-model="reviewFormList.replacePaper" size="small">-->
<!--                  <el-radio :label="1">是</el-radio>-->
<!--                  <el-radio :label="0">否</el-radio>-->
<!--                </el-radio-group>-->
<!--              </el-form-item>-->
<!--&lt;!&ndash;               上传框，仅在选中“是”时显示 &ndash;&gt;-->
<!--              <el-form-item v-if="reviewFormList.replacePaper === 1" label-width="100px">-->
<!--                <el-row :gutter="10">-->
<!--                  <el-col :span="10">-->
<!--                    <el-upload-->
<!--                      class="upload-demo smaller-upload"-->
<!--                      ref="paperRaw"-->
<!--                      :auto-upload="false"-->
<!--                      :on-success="uploadSuccess"-->
<!--                      :on-error="uploadError"-->
<!--                      :file-list="fileList"-->
<!--                      :before-upload="beforeUpload"-->
<!--                      :on-change="handleChange"-->
<!--                      drag-->
<!--                    >-->
<!--                      <i class="el-icon-upload"></i>-->
<!--                      <div class="el-upload__text">-->
<!--                        将论文原件拖到此处，或-->
<!--                        <em>点击上传</em>-->
<!--                      </div>-->
<!--                      <div class="el-upload__tip" slot="tip">只能上传PDF文件。</div>-->
<!--                    </el-upload>-->
<!--                  </el-col>-->
<!--                </el-row>-->
<!--              </el-form-item>-->
<!--              <el-form-item label label-width="80px">-->
<!--                <el-popconfirm title="是否进行复审？" @confirm="confirmReview">-->
<!--                  <el-button-->
<!--                    style="margin:0 10px;"-->
<!--                    slot="reference"-->
<!--                    type="success"-->
<!--                    size="small"-->
<!--                  >开始复审</el-button>-->
<!--                </el-popconfirm>-->
<!--              </el-form-item>-->
<!--            </el-form>-->
<!--            <el-button slot="reference" type="primary" style="margin-right:10px;" size="small">复审</el-button>-->
<!--          </el-popover>-->
<!--        </el-col>-->
<!--      </el-row> -->
      <vxe-table
        :header-cell-style="headerCellStyle"
        class="mytable-scrollbar"
        round
        align="left"
        ref="reviewTable"
        highlight-current-row
        highlight-hover-row
        :keyboard-config="{isArrow: true}"
        :checkbox-config="{trigger: 'cell', highlight: true, range: false}"
        :loading="loading"
        :data="reviewList"
      >
        <vxe-column type="seq" title="序号" width="60" align="center" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column
          field="reviewFilename"
          title="评阅书"
          width="450"
          align="center"
          :show-overflow="'tooltip'"
        ></vxe-column>
        <!--                <vxe-column field="expertName" title="评审人姓名" width="150" :show-overflow="'tooltip'"></vxe-column>-->
        <!--                <vxe-column field="expertOrg" title="评审人单位" width="150" :show-overflow="'tooltip'"></vxe-column>-->
        <vxe-column
          field="reviewContent"
          title="分数"
          width="150"
          align="center"
          :show-overflow="'tooltip'"
        ></vxe-column>
        <!--                <vxe-column field="reviewStatus" :formatter="formatterReviewStatus" title="状态" width="100" sor table></vxe-column>-->
        <vxe-column
          field="reviewPass"
          title="是否通过"
          :formatter="formatterReviewPass"
          width="150"
          align="center"
          :show-overflow="'tooltip'"
        ></vxe-column>
        <!--                <vxe-column title="操作" min-width="120">-->
        <!--                    <template #default="{ row }">-->
        <!--                        <el-button size="mini" type="text">复审</el-button>-->
        <!--                    </template>-->
        <!--                </vxe-column>-->
        <vxe-column title="评阅书下载" width="150" align="center">
          <template #default="{ row }">
            <el-button
              v-if="row.reviewStatus === 1"
              size="mini"
              type="success"
              @click="downloadOneReviewBook(row)"
            >下载</el-button>
          </template>
        </vxe-column>
      </vxe-table>
      <!-- <template #footer>
          <el-button type="primary" size="small" @click='cfmExport'>确认</el-button>
      </template>-->
    </vxe-modal>

    <!-- 弹窗 -->
    <vxe-modal v-model="exportOneReviewModel" :position="{top: '0px'}" width="600" height="200" :show-footer='true'
               show-zoom resize :transfer='true'>
      <template #title>
        导出评阅书
      </template>
      <vxe-form :data="download" title-align="right" title-width="250">
        <vxe-form-item field="sendType" title="是否包含评审人信息：" :span="24" :item-render="{}">
          <template #default="{ data }">
            <vxe-radio-group v-model="data.isWithExpert">
              <vxe-radio :label="1" content="是" style="width:80px;"></vxe-radio>
              <vxe-radio :label="0" content="否" style="width:80px;"></vxe-radio>
              <vxe-radio :label="2" content="全部" style="width:80px;"></vxe-radio>
            </vxe-radio-group>
          </template>
        </vxe-form-item>
      </vxe-form>
      <template #footer>
        <el-button type="primary" size="small" @click="confirmOneExportReview">确认</el-button>
      </template>
    </vxe-modal>

  </div>
</template>

<script>
// import dateFormat from '../../../public/tools/date'
export default {
  name: "PaperPage",
  data() {
    return {
      batchShow:true,
      rules: {
        experts: [
          { required: true, message: "至少选择一位专家", trigger: "blur" }
        ],
        type: [
          { required: true, message: "请选择对原专家的操作", trigger: "blur" }
        ],
        expertNum: [
          { required: true, message: "请输入新增专家数量", trigger: "blur" }
        ],
        note: [
          {
            required: true,
            message: "请选择是否告知专家为复审论文",
            trigger: "blur"
          }
        ],
        returnTime: [
          { required: true, message: "请选择截至时间", trigger: "blur" }
        ],
        replacePaper: [
          { required: true, message: "请选择是否替换论文", trigger: "blur" }
        ]
        // 其他字段的验证规则
      },
      fileList: [],
      // uploadData: {}, // 上传时需要携带的数据
      percent: 0,
      uploadTitle: "",
      uploadDiaVis: false,
      proStatus: "",
      checkSuccess: false,
      reviewFormList: {
        experts: [],
        type: 1,
        note: 1,
        replacePaper: 1,
        returnTime: "2025-3-12",
        expertNum: 2,
        paperId: "",
        oldId: "",
        batchId: ""
      },
      addPaperId: "",
      addModel: false,
      singleConditionModel: false,
      //数据加载
      loading: true,
      //输入条件
      input3: "",
      select: "1",
      //搜索条件
      institutionList: [],
      batchList: [],
      singleSearchLabel: "title",
      singleSearchValue: "",
      student: "",
      search: {
        institutionId: "",
        batchId: "",
        title: "",
        sendingRuleId: "",
        author: "",
        state: "",
        downloadState: "",
        isPass: "",
        supervisor: "",
        scoreDifference: "",
        oldId:"",
        studentCollege:"",
        studentNum:"",
      },
      //论文列表
      paperList: [],
      // reviewList: [{
      //     reviewNum: '123',
      //     name: '张三',
      //     institution: '南京理工大学',
      //     score: 80,
      //     statue: '1'
      // }],
      reviewList: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        pageCount: 40, //自己用
        pageSizes: [
          10,
          50,
          100,
          200,
          500,
          {
            label: "全部数据",
            value: 10000
          }
        ],
        total: 100
      },
      //对话框
      receiptDiaVis: false,
      //送审情况弹窗
      conditionModel: false,
      // 导出评阅书弹窗
      exportReviewModel: false,
      //下载对话框中的信息
      download: {
        isWithExpert: 2,
        isWithMajor: 1,
        form: 2,
        range: 0,
        reviewNum: "0",
        paperNum: "0",
        gap: 0,
        bottom: 0,
        type: 1,
      },
      // 导出评审信息表
      exportTableModel: false,
      // 导出并预存弹窗
      exportTableSaveModel: false,
      // 更新评阅书
      updataReviewModel: false,
      //评阅书列表
      receiptList: [
        {
          expertName: "sh",
          university: "正高",
          state: "0",
          resultKeywords: "",
          paperKeywords: ""
        }
      ],
      formData: {
        paperInfos: [],
        expertInfos: [],
        reviewInfos: []
      },
      addData: {
        expertName: "",
        debExpertInstitution: "",
        returnTime: ""
      },
      paperInfos: [
        "论文编号",
        "论文名称",
        "学生姓名",
        "学生专业",
        "学位类型",
        "导师姓名"
      ],
      expertInfos: [
        "专家姓名",
        "专家单位",
        "专家职称",
        "专家类型",
        "专家邮箱",
        "专家手机号"
      ],
      reviewInfos: [
        "论文选题",
        "论文综述",
        "独立解决实际问题的能力",
        "工作量",
        "技术难度与先进性",
        "新思想或新方法经济效益或社会效益",
        "结构严谨性",
        "语言通顺性",
        "论文写作水平正确性",
        "论文写作水平规范性",
        "综合意见",
        "总体评价",
        "熟悉程度"
      ],
      paperKeywords: "",
      //发送进度条
      sendProcessVis: false,
      showClose: false,
      sendProcessTitle: "发送中",
      process: 0,
      sendTimer: "",
      sendRuleList: [],
      reviewStatusList: [
        {
          label: "评审中",
          value: 0
        },
        {
          label: "评审完成",
          value: 1
        },
        {
          label: "待接审",
          value: 2
        }
      ],
      reviewPassList: [
        {
          label: "未通过",
          value: 0
        },
        {
          label: "通过",
          value: 1
        }
      ],
      exportOneReviewModel: false,
      currentReviewId: null, // 用于存储当前选择的评阅书的 reviewId
      currentReviewFilename: null
    };
  },
  mounted() {},
  // activated() {
  //     this.$nextTick(() => {
  //         console.log(this.$store.state.sendRecord.scrollTop);
  //         this.$refs.sendRecord.parentElement.scrollTop = this.$store.state.sendRecord.scrollTop;
  //     })
  // },
  created() {
    //获取论文列表
    this.getAllBatch();
    this.getPaperList();
    this.getInstitution();
    this.getAllInstitution()
    //开启定时任务
    // this.timer();
  },
  methods: {
    //复审
    confirmReview() {
      console.log(this.fileList)
      if (
        this.reviewFormList.replacePaper === 1 &&
        this.fileList.length === 0
      ) {
        this.$message.warning("请上传论文文件！");
        return;
      }

      this.$refs.reviewForm.validate(valid => {
        console.log(valid);
        if (valid) {
          this.submitReview();
        } else {
          this.$message.warning("请填写完整的表单信息！");
        }
      });
    },
    submitReview() {
      // const formData = new FormData();
      // // 添加表单数据
      // for (const key in this.reviewFormList) {
      //   formData.append(key, this.reviewFormList[key]);
      // }
      // // 如果需要替换论文，添加文件
      // if (this.reviewFormList.replacePaper === 1 && this.fileList.length > 0) {
      //   formData.append("paper", this.fileList[0].raw);
      // }

      // // 发送请求到后端
      // axios
      //   .post("/your-backend-api-endpoint", formData, {
      //     headers: {
      //       "Content-Type": "multipart/form-data"
      //     }
      //   })
      //   .then(response => {
      //     // 处理成功响应
      //     this.$message.success("复审提交成功！");
      //   })
      //   .catch(error => {
      //     // 处理错误响应
      //     this.$message.error("复审提交失败！");
      //   });
      const formData = new FormData();
      // 添加表单数据
      for (const key in this.reviewFormList) {
        formData.append(key, this.reviewFormList[key]);
      }
      // 如果需要替换论文，添加文件
      if (this.reviewFormList.replacePaper === 1 && this.fileList.length > 0) {
        formData.append("file", this.fileList[0].raw);
      }
      this.$api.paperLibrary
        .reviewPaper(formData)
        .then(res => {
          console.log(res);
          if (res.data.code == 200) {
            this.$message.success("加送成功！");
            this.addModel = false;
            this.getAllBatch();
          } else {
            this.$message.warning("加送失败！");
          }
        })
        .catch(err => {
          this.$message.warning("服务器维护！");
        });
    },

    // confirmReview() {
    //   console.log(this.reviewFormList);
    //   var paperDetails = [];
    //   var paps = {
    //     paperId: this.reviewFormList.paperId,
    //     oldId: this.reviewFormList.oldId
    //   };
    //   console.log(paps);
    //   paperDetails.push(paps);
    //   this.uploadData.batchId = this.reviewFormList.batchId;
    //   this.uploadData.paperDetails = JSON.stringify(paperDetails);
    //   this.$refs.paperRaw.submit();
    //   this.percent = 0;
    //   this.showClose = false;
    //   this.uploadTitle = "正在上传论文原件，请耐心等待";
    //   this.proStatus = "";
    //   this.checkSuccess = false;
    //   this.uploadDiaVis = true;
    // },
    closeHandle() {
      this.$refs.paperRaw.uploadFiles.length = 0;
    },
    //上传论文
    uploadSuccess(res, file, fileList) {
      console.log("there")
      this.fileList=fileList;
      if (res.code == "200") {
        this.$message.success("上传成功");
        this.fileList=fileList;
        this.uploadTitle = "上传成功";
        this.proStatus = "success";
      } else if (res.code == "406") {
        this.showClose = true;
        this.uploadTitle = "上传失败";
        this.errorUrl = res.data;
        this.proStatus = "exception";
        this.percent = 99;
      } else {
        this.uploadTitle = "上传失败";
        this.proStatus = "exception";
        this.$message.warning(res.msg);
      }
      this.$refs.paperRaw.clearFiles();
    },
    uploadError(err, file, fileList) {
      this.$message.warning("服务器维护！");
      this.showClose = true;
      this.percent = 99;
      this.uploadTitle = "上传失败";
      this.proStatus = "exception";
      this.$refs.paperRaw.clearFiles();
    },
    uploadProgress(event, file, fileList) {
      this.percent = parseInt(event.percent);
    },
    downloadError() {
      // console.log(this.errorUrl.substring(this.errorUrl.lastIndexOf('\/')));
      this.getTable(
        "/uploadTemp" + this.errorUrl.substring(this.errorUrl.lastIndexOf("/"))
      );
    },
    handleChange(file, fileList) {
      this.fileList = fileList;
    },
    beforeUpload(file) {
      const isPDF = file.type === 'application/pdf';
      if (!isPDF) {
        this.$message.error('只能上传pdf文件!');
      }
      return isPDF;
    },
    //加送
    addPaper() {
      let params = {
        paperId: this.addPaperId,
        returnTime: this.addData.returnTime,
        expertName: this.addData.expertName
        // debExpertInstitution: this.addData.debExpertInstitution,
      };
      this.$api.paperLibrary
        .addPaper(params)
        .then(res => {
          console.log(res);
          if (res.data.code == 200) {
            this.$message.success("加送成功！");
            this.addModel = false;
            this.getAllBatch();
          } else {
            this.$message.warning("加送失败！");
          }
        })
        .catch(err => {
          this.$message.warning("服务器维护！");
        });
    },
    // 下载错误原因zip
    getTable(path) {
      this.$api.file
        .getTables({
          path: path
        })
        .then(res => {
          console.log(res);
          let data = res.data;
          let filename = "";
          // 设置文件名为 "上传错误原因.xls"
          if (this.withdrawFileName == true) {
            filename = "撤回失败原因.xls";
          } else filename = "上传错误原因.xls";
          let blob = new Blob([data], {
            type: "application/octet-stream;charset=utf-8"
          });
          let url = window.URL || window.webkitURL;
          let href = url.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = href;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch(err => {
          this.$message.warning("导出格式有误");
        });
    },
    //加送弹窗
    addDialog(paperId, batchId, paperName) {
      this.addModel = true;
      this.addPaperId = paperId;
    },
    // 获取所有批次
    getAllBatch() {
      this.$api.paperLibrary
        .getAllBatch()
        .then(res => {
          if (res.data.code == 200) {
            this.batchList = res.data.data;
            // this.search.batchId = this.sendRuleList ? this.sendRuleList[0].batchId : '';
            // this.batchSearch();
          }
        })
        .catch(err => {
          this.$message.warning("服务器维护");
        });
    },
    getInstitution() {
      this.$api.user.getInstitution().then(res => {
        this.getSendingRules(res.data.data.institutionId);
      });
    },
    // 获取送审规则
    getSendingRules(institutionId) {
      let param = {
        institutionId: institutionId
      };
      this.$api.sendManage
        .getSendingRules(param)
        .then(res => {
          this.sendRuleList = res.data.data;
        })
        .catch(err => {
          this.$message.warning("获取送审规则错误");
        });
    },
    // 获取论文列表
    getPaperList() {
      this.loading = true;
      let params = {
        batchId: this.search.batchId,
        title: this.search.title,
        state: this.search.state,
        author: this.search.author,
        sendingRuleId: this.search.sendingRuleId,
        downloadState: this.search.downloadState,
        isPass: this.search.isPass,
        pageIndex: this.page.pageIndex,
        pageSize: this.page.pageSize,
        supervisor: this.search.supervisor,
        scoreDifference: this.search.scoreDifference,
        institutionId: this.search.institutionId,
        oldId:this.search.oldId,
        studentCollege:this.search.studentCollege,
        studentNum:this.search.studentNum
      };
      this.$api.sendManage
        .getPaperList(params)
        .then(res => {
          if (res.data.code == 200) {
            console.log("获取论文列表", res.data);
            this.paperList = res.data.data;
            this.page.total = res.data.count;
            this.loading = false;
          }
        })
        .catch(err => {
          this.$message.warning("服务器维护！");
        });
    },
    // 下载zip
    getZip(path) {
      this.$api.sendManage
        .getZip({
          path: path
        })
        .then(res => {
          console.log(res);
          let data = res.data;
          let str = res.headers["content-disposition"]
            .split(";")[1]
            .split("=")[1];
          let filename = "";
          for (let i = 1; i < str.length - 1; i++) filename += str[i];
          let blob = new Blob([data], {
            type: "application/octet-stream;charset=utf-8"
          });
          let url = window.url || window.webkitURL;
          let href = url.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = href;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
          this.loading.close();
        })
        .catch(err => {
          this.$message.warning("导出格式有误");
        });
    },
    //导出评阅书
    exportReviewHandle() {
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      if (selectRecords.length <= 0) {
        this.$message.warning("请选择论文！");
        return;
      }
      // this.confirmExportReview();
      this.exportReviewModel = true;
    },
    confirmExportReview() {
      if (this.download.type === 2 && this.download.isWithMajor === 0){
        this.$message.warning("按论文信息划分时，不能选择‘无’选项");
        return;
      }
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      let paperIds = [];
      selectRecords.forEach(item => {
        if (item.finishedNum > 0) paperIds.push(item.paperId);
      });
      if (paperIds.length <= 0) {
        this.$message.warning("请选择已完成的论文！");
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      let params = {
        // paperIds: paperIds.join(','),
        paperIds: paperIds.join(","),
        isWithExpert: this.download.isWithExpert,
        isWithMajor: this.download.isWithMajor,
        form: this.download.form,
        range: this.download.range,
        gap: this.download.gap,
        bottom: this.download.bottom,
        isPublish: 0,
        type:this.download.type
      };
      this.$api.sendManage
        .getReviewsZip(params)
        .then(res => {
          if (res.data.code == 200) {
            this.getZip(res.data.data[0]);
            // this.getExcel(res.data.data[1]);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch(err => {
          this.$message.warning("生成压缩包错误！");
        });
    },
    exportTableHandle() {
      // this.exportTableModel = true;
      let selectRecords = this.$refs.paperTable.getCheckboxRecords();
      if (selectRecords.length <= 0) {
        this.$message.warning("请选择论文！");
        return;
      }
      let paperIds = [];
      selectRecords.forEach(item => {
        if (item.finishedNum > 0) paperIds.push(item.paperId);
      });
      if (paperIds.length <= 0) {
        this.$message.warning("请选择已完成的论文！");
        return;
      }
      // console.log(reviewIds);
      // let params = {
      //     reviewId: reviewIds
      // }
      const loading = this.$loading({
        lock: true,
        text: "下载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api.sendManage
        .downloadSchoolExcel(paperIds)
        .then(res => {
          if (res.data.code && res.data.code == 400) {
            this.$message.warning(res.data.msg);
          } else {
            const blob = new Blob([res.data], {
              type: "application/octet-stream;charset=utf-8"
            });
            let url = window.URL || window.webkitURL;
            const href = url.createObjectURL(blob);
            let downloadElement = document.createElement("a");
            downloadElement.href = href;
            downloadElement.download = `评审信息文件_${this.dateFormat(
              new Date()
            )}.zip`;
            // downloadElement.download = this.getFileName(res.data.data); //下载后文件名
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象
          }
          loading.close();
        })
        .catch(err => {
          this.$message.warning("导出评审信息表错误");
        });
    },
    // 下载excel
    getExcel(path) {
      this.$api.sendManage
        .getTables({
          path: path
        })
        .then(res => {
          const blob = new Blob([res.data], {
            type: "Application/vnd.ms-excel;charset=utf-8"
          });
          let url = window.URL || window.webkitURL;
          const href = url.createObjectURL(blob);
          let downloadElement = document.createElement("a");
          downloadElement.href = href;
          downloadElement.download = this.getFileName(path); //下载后文件名
          document.body.appendChild(downloadElement);
          downloadElement.click(); //点击下载
          document.body.removeChild(downloadElement); //下载完成移除元素
          window.URL.revokeObjectURL(href); //释放掉blob对象
          loading.close();
        });
    },
    changeHandle(item) {},
    cfmExportSave() {
      this.exportTableSaveModel = true;
    },
    //表格刷新
    refresh() {
      this.getPaperList();
    },
    // <vxe-column field="reviewNum" title="评审中" width="150" sortable>
    //         <template #default="{ row }">
    //             {{row.reviewNum + row.sendingNum}}/{{row.expertNumber}}
    //         </template>
    //     </vxe-column>
    //     <vxe-column field="finishedNum" title="已完成" width="150" sortable>
    //         <template #default="{ row }">
    //             {{row.finishedNum}}/{{row.expertNumber}}
    //         </template>
    //     </vxe-column>
    footerMethod({ columns, data }) {
      const sums = [];
      let finishedNum = 0,
        sendingNum = 0,
        expertNumber = 0,
        reviewExportTime = 0,
        notPassNum = 0;
      data.forEach(item => {
        finishedNum += item.finishedNum;
        sendingNum += item.sendingNum + item.reviewNum;
        expertNumber += item.expertNumber;
        reviewExportTime += item.reviewExportTime;
        notPassNum += item.notPassNum;
      });
      columns.forEach((column, columnIndex) => {
        if (columnIndex === 0) {
          sums.push("总计");
        } else {
          switch (column.property) {
            case "reviewNum":
              sums.push(sendingNum + "/" + expertNumber);
              break;
            case "finishedNum":
              sums.push(finishedNum + "/" + expertNumber);
              break;
            case "reviewExportTime":
              sums.push(reviewExportTime + "/" + expertNumber);
              break;
            case "notPassNum":
              sums.push(notPassNum + "/" + expertNumber);
              break;
            default:
              sums.push("-");
          }
        }
      });
      console.log(sums);
      // 返回一个二维数组的表尾合计
      return [sums];
    },

    //查看单篇论文的送审情况
    singleConditionHandle(paperId, batchId, paperName, oldId) {
      console.log(paperId);
      this.reviewFormList.paperId = paperId;
      this.reviewFormList.oldId = oldId;
      this.reviewFormList.batchId = batchId;
      let params = {
        paperId: paperId
      };
      this.$api.sendManage
        .getReviewDetailsByPaperId({
          params
        })
        .then(res => {
          // console.log(res);
          this.reviewList = res.data.data;
          console.log(this.reviewList);

          this.singleConditionModel = true;
        })
        .catch(err => {
          this.$message.warning("服务器维护");
        });
    },
    //获取发送精度
    getProcess() {
      this.$axios
        .get("sending_paper/progress")
        .then(res => {
          this.process = res.data.data;
        })
        .catch(err => {
          this.$message.warning("服务器维护");
        });
    },
    batchSearch() {
      this.page.pageIndex = 1;
      this.getPaperList();
    },
    resetHandle() {
      this.search = {
        institutionId: "",
        batchId: "",
        title: "",
        sendingRuleId: "",
        student: "",
        statue: "",
        downloadState: "",
        isPass: "",
        supervisor: "",
        scoreDifference: "",
        oldId:"",
        studentCollege:"",
        studentNum:""
      };
    },
    //获取评阅书
    handleLookReview(paperId) {
      this.receiptDiaVis = true;
    },
    //查看评阅书
    handleLook(url) {
      window.open(url);
    },
    //下载评阅书
    handleDownloadReview() {},
    //分页操作
    handlePageChange({ currentPage, pageSize }) {
      this.page.pageIndex = currentPage;
      this.page.pageSize = pageSize;
      this.getPaperList();
    },
    //每页多少数据改变
    handleChangePageSize(val) {
      this.page.pageSize = val;
      this.page.pageIndex = 1;
      this.getPaperList();
    },
    firstPage() {
      this.page.pageIndex = 1;
      this.getPaperList();
    },
    endPage() {
      this.page.currentPage =
        parseInt(this.page.total / this.page.pageSize) +
        (this.page.total % this.page.pageSize == 0 ? 0 : 1);
      this.getPaperList();
    },
    dateFormat: function(time) {
      var datetime = new Date(time);
      // datetime.setTime(time);
      var year = datetime.getFullYear();
      var month =
        datetime.getMonth() + 1 < 10
          ? "0" + (datetime.getMonth() + 1)
          : datetime.getMonth() + 1;
      var date =
        datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
      var hour =
        datetime.getHours() < 10
          ? "0" + datetime.getHours()
          : datetime.getHours();
      var minute =
        datetime.getMinutes() < 10
          ? "0" + datetime.getMinutes()
          : datetime.getMinutes();
      var second =
        datetime.getSeconds() < 10
          ? "0" + datetime.getSeconds()
          : datetime.getSeconds();
      return (
        year +
        "_" +
        month +
        "_" +
        date +
        "_" +
        hour +
        "_" +
        minute +
        "_" +
        second
      );
    },
    headerCellStyle({ row, column, rowIndex, columnIndex }) {
      return "background-color:#e0e0e0f1";
    },
    scroll(e) {
      console.log("scroll", e.target.scrollTop);
    },
    // formatterReviewStatus(){
    //
    // },
    formatterReviewStatus({ cellValue }) {
      let item = this.reviewStatusList.find(item => item.value === cellValue);
      return item ? item.label : "";
    },
    formatterReviewPass({ cellValue }) {
      let item = this.reviewPassList.find(item => item.value === cellValue);
      return item ? item.label : "";
    },
    //导出单篇评阅书
    downloadOneReviewBook(row) {
      // 将当前行的 reviewId 存储到 this.currentReviewId
      this.currentReviewId = row.reviewId;
      this.currentReviewFilename = row.reviewFilename;
      this.exportOneReviewModel = true;
    },
    confirmOneExportReview() {
      // 检查是否有选中的 reviewId
      if (!this.currentReviewId) {
        this.$message.warning("无法获取评阅书的 ID！");
        return;
      }

      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      let params = {
        // paperIds: paperIds.join(','),

        reviewId: this.currentReviewId,
        isWithExpert: this.download.isWithExpert,
        form: this.download.form,
        range: this.download.range,
        gap: this.download.gap,
        bottom: this.download.bottom,
        isPublish: 0
      };
      this.$api.sendManage
        .getOneReviewsZip(params)
        .then(res => {
          if (res.data.code == 200) {
            this.getOneZip(res.data.data[0]);
            // this.getExcel(res.data.data[1]);
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch(err => {
          this.$message.warning("生成压缩包错误！");
        });
    },

    // 下载zip
    getOneZip(path) {
      this.$api.sendManage
        .getZip({ path: path })
        .then(res => {
          // 获取响应的文件内容
          let data = res.data;

          // 假设后端返回的文件名为时间戳等，可以根据 reviewFilename 来命名
          let reviewFilename =
            this.currentReviewFilename || "default_review_filename"; // 如果没有 reviewFilename，使用默认名

          // 创建一个 Blob 对象
          let blob = new Blob([data], {
            type: "application/octet-stream;charset=utf-8"
          });

          // 创建下载链接
          let url = window.URL || window.webkitURL;
          let href = url.createObjectURL(blob);
          let a = document.createElement("a");

          // 设置下载的文件名为 reviewFilename
          a.href = href;
          a.download = reviewFilename + ".zip"; // 使用 reviewFilename 作为文件名

          // 模拟点击下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);

          // 释放下载链接
          window.URL.revokeObjectURL(href);

            // 关闭加载提示
            this.loading.close();
          })
          .catch(err => {
            this.$message.warning("导出格式有误");
          });
    },
    handleInstitution(val) {
      this.search.batchId = '';
      if (val == '') {
        this.batchShow = true;
        return;
      }
      this.batchShow = false;
      this.getBatch(val);
    },
    getAllInstitution() {
      let params = {
        // isFiled: null,
        institutionAccountId: this.userId
      }
      console.log(params)
      this.$api.paperLibrary.getAllInstitution(params)
          .then(res => {
            console.log(res);
            this.institutionList= res.data.data;
          })
          .catch(err => {
            this.$message.warning('接口错误');
          })
    },
    getBatch(institutionId) {
      // let params = new URLSearchParams();
      // params.append("institutionId", institutionId);
      // params.append("isFiled",null);
      let params = {
        institutionId:institutionId,
        isFiled: null
      }
      console.log(params)
      this.$api.paperLibrary.getSchoolBatch(params)
          .then(res => {
            console.log(res.data.data);
            this.batchList = res.data.data;
          })
          .catch(err => {
            this.$message.warning("服务器维护");
          });
    },


  },
  destroyed() {},
  //在页面离开时记录滚动位置
  // beforeRouteLeave(to, from, next) {
  //     this.$store.dispatch('changeScrollTop', this.$refs.sendRecord.parentElement.scrollTop);
  //     next()
  // },
  //进入该页面时，用之前保存的滚动位置赋值
  // beforeRouteEnter(to, from, next) {
  //     next(vm => {
  //         console.log(vm.scrollTop);
  //         console.log(vm.$refs)
  //     })
  // },
  components: {}
};
</script>

<style scoped>
.inputSelect {
  width: 120px;
}

.collapseInput {
  margin-top: 20px;
}

.searchBtn {
  padding: 20px 0px 0px 0px;
  text-align: center;
}

.box-card {
  padding-bottom: 0px !important;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.form-line-item {
  width: 15em;
}

.el-form-item {
  margin-bottom: 10px !important;
}
</style>
<style>
.paperPage .vxe-table--body-wrapper {
  height: calc(100vh - 388px);
}

.dialog .el-dialog {
  margin-top: 5vh !important;
  border-radius: 15px;
}

.searchForm .el-form-item__label {
  background-color: #f5f7fa;
  color: #909399;
}
/* 添加自定义样式来限制上传框的大小 */
.smaller-upload .el-upload {
  width: 270px; /* 设置固定的宽度 */
  height: 150px; /* 设置固定的高度 */
}
.smaller-upload .el-upload-dragger {
  width: 100%; /* 确保拖拽区域占满整个上传框 */
  height: 100%; /* 确保拖拽区域占满整个上传框 */
}
</style>